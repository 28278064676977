import moment from 'moment';
import {
  getMoney, getPercent, getNumber,
} from '../../common/functions/helpers';
import InputMoney from '../../shared-components/inputs/InputMoney';
import PlanejamentoContratoForm from '../planejamento-contrato/form/PlanejamentoContratoForm';
import DialogApuracaoContratoCancelamento from './modals/DialogApuracaoContratoCancelamento';
import DialogCategoriaNivelFamilia from './modals/DialogCategoriaNivelFamilia';
import DialogApuracaoContratoMetaIntervalo from './modals/DialogApuracaoContratoMetaIntervalo';
import DialogEditRecebimentoDefinido from './modals/DialogEditRecebimentoDefinido';
import DialogApuracaoContratoNotaDebitoInformacoes from './modals/DialogApuracaoContratoNotaDebitoInformacoes';
import { cancelarApuracao } from '../../common/resources/apuracao/apuracao-acao-edicao';
import { verificarDadosExportacao } from '../../../common/resources/solicitacao-exportacao-assincrona-realizado';
import PopoverGenerico from '../../shared-components/PopoverGenerico';
import exportacao from '../../common/functions/exportacao';

export default {
  components: {
    PlanejamentoContratoForm,
    DialogApuracaoContratoCancelamento,
    DialogCategoriaNivelFamilia,
    DialogApuracaoContratoMetaIntervalo,
    DialogEditRecebimentoDefinido,
    DialogApuracaoContratoNotaDebitoInformacoes,
    InputMoney,
    PopoverGenerico,
  },
  data() {
    return {
      workflowApuracaoAcao: this.$api.workflowApuracaoAcao(this.$resource),
      apuracaoAcaoResource: this.$api.apuracaoAcaoEdicao(this.$resource),
      resourceApuracaoAcaoListagem: this.$api.apuracaoAcaoListagem(this.$resource),
      resourceContrato: this.$api.apuracaoContrato(this.$resource),
      panelKey: 0,
      totalPageApuracao: 0,
      esperar: false,
      recebimentoSemValorNaoEmitido: true,
      apuracaoSelecionada: {},
      modalJustificativaFlag: '',
      isModoEdicaoVlrFornecedor: false,
      prefixRefNameRecDefinido: 'modalRecDefinido-',
      prefixRefNameVBDefinido: 'modalVBDefinido-',
      prefixRefNameTipoUso: 'modalTipoUso-',
      dialogPlanejamentoContrato: false,
      contrato: null,
      acao: null,
      rules: {
        required: (value) => (!!value || value === 0) || this.$t('message.campo_obrigatorio'),
        valorAjusteNegativo: (value) => value >= 0 || this.$t('errors.valor_negativo'),
        valorPositivo: (value) => (!!value && value > 0) || this.$t('errors.valor.negativo'),
        valorMaiorQueEfetuado: (value, valorRecebido) => (!!value && value >= valorRecebido)
        || this.$t('errors.valor.menor_que_efetuado'),
      },
      headersApuracao: [
        { text: '', value: 'checkbox', sortable: false },
        { text: '', value: 'icon', sortable: false },
        {
          text: this.$tc('label.acao', 2), value: 'acoes', class: 'nowrap', sortable: false,
        },
        {
          text: this.$tc('label.cod_contrato', 1), value: 'idContrato', class: 'nowrap',
        },
        {
          text: this.$tc('label.fornecedor', 1), value: 'origemContrato', class: 'nowrap', width: '25%',
        },
        {
          text: this.$tc('label.cod_apuracao', 1), value: 'idApuracao', class: 'nowrap',
        },
        {
          text: this.$tc('title.categorias', 1), value: 'categoria', class: 'nowrap', cellClass: 'nowrap', width: '15%',
        },
        {
          text: this.$tc('label.periodo_vigencia', 1), value: 'periodoVigencia', class: 'nowrap', width: '15%',
        },
        {
          text: this.$tc('label.calculo_apuracao', 1), value: 'calculoApuracao', class: 'nowrap',
        },
        {
          text: this.$tc('label.verba', 1), value: 'verba', class: 'nowrap', cellClass: 'nowrap',
        },
        {
          text: this.$tc('label.realizado', 1), value: 'vlrRealizado', class: 'nowrap', cellClass: 'nowrap',
        },
        {
          text: this.$tc('label.valor_base', 1), value: 'valorBase', class: 'nowrap', cellClass: 'nowrap',
        },
        {
          text: this.$tc('label.recebimento_definido', 1), value: 'recebimentoDefinido', cellClass: 'nowrap', class: 'nowrap',
        },
        {
          text: this.$tc('label.ajuste_geral', 1), value: 'ajusteGeral', width: '5%', sortable: false,
        },
      ],
      apuracaoDetalhes: [],
      expanded: [],
      indJustificativa: false,
      metas: [],
      idsApuracaoDetalhes: [],
      exibeModalRecebimentoParcial: false,
      apuracaoParcial: {},
      tamanhoTela: null,
      isEditandoValorBase: false,
      subTitulo: '',
    };
  },
  methods: {
    getPercent,
    getMoney,
    getNumber,
    mesmaPagina(p1, p2) {
      return p1.page === p2.page && p1.itemsPerPage === p2.itemsPerPage;
    },
    onResize() {
      this.tamanhoTela = window.innerWidth;
    },
    ordenacaoAlterada(p1, p2) {
      if ((p1.sortBy.length && !p2.sortBy.length) || (!p1.sortBy.length && p2.sortBy.length)) {
        return true;
      }
      if (p1.sortBy.length && p2.sortBy.length) {
        if (p1.sortBy[0] !== p2.sortBy[0]) {
          return true;
        }
        if (p1.sortBy[0] === p2.sortBy[0] && p1.sortDesc[0] !== p2.sortDesc[0]) {
          return true;
        }
      }
      return false;
    },
    statusAprovado(item) {
      return item.statusApuracao === 'APROVADO';
    },
    indCalculoSellout(item) {
      return !!(item.calculoApuracao === 'SELLOUT_VALOR');
    },
    indRecebimentoDefinidoAlterado(item) {
      return this.formatarValor(item.recebimentoDefinido) !== this.formatarValor(item.recebimentoAtual) && item.justificativaAlterarRecebimento;
    },
    formatarVigencia(item) {
      const dtaInicio = moment(item.dataInicio, 'DD/MM/YYYY').format('MMMM/YY').toUpperCase();
      const dtaFim = moment(item.dataFim, 'DD/MM/YYYY').format('MMMM/YY').toUpperCase();
      if (dtaInicio === dtaFim) {
        return dtaInicio;
      }
      return dtaInicio.concat(' a ').concat(dtaFim);
    },
    formatarCalculoApuracao(calculoApuracao) {
      return calculoApuracao ? this.$tc(`label.${calculoApuracao.toLowerCase()}`, 1) : this.$tc('label.nao_aplica', 1);
    },
    formatarValor(valor) {
      if (valor === 0) {
        return '-';
      }
      return this.getMoney(valor) || '-';
    },
    detalharApuracao(item) {
      this.dialogPlanejamentoContrato = true;
      this.contrato = item;
    },
    closeDialogPlanejamentoContrato() {
      this.dialogPlanejamentoContrato = false;
    },
    abrirModalCancelamento(item) {
      this.$refs.dialogCancelamento.open(item);
    },
    abrirModalCategoriaNivelFamilia(idAcao) {
      this.$refs.dialogCategoriaNivelFamilia.open(idAcao);
    },
    abrirModalIntervaloMeta(item) {
      const idAcao = Number(item.idAcao);
      const {
        calculoApuracao, formaBonificacao, faixaAtingida, existeAtingimento,
      } = { ...item };
      this.$refs.dialogMetaIntervalo.open(idAcao, calculoApuracao, formaBonificacao, faixaAtingida, existeAtingimento);
    },
    abrirModalNotaDebitoInformacoes(item) {
      const idContrato = Number(item.idContrato);
      const idApuracao = Number(item.idApuracao);
      const somenteLeitura = this.statusAprovado(item);
      this.$refs.dialogNotaDebitoInformacoes.open(idContrato, idApuracao, somenteLeitura);
    },
    confirmarCancelarApuracao(item, justificativaCancelamento) {
      const apuracao = item;
      const { idApuracao } = apuracao;
      const param = {
        idApuracao,
        justificativa: justificativaCancelamento,
      };
      cancelarApuracao(param, this.$resource)
        .then(() => {
          const index = this.apuracoes.findIndex((a) => a.idApuracao === idApuracao);
          this.apuracoes.splice(index, 1);
          this.$toast(this.$t('message.apuracao_cancelada_sucesso'));
          this.apuracaoSelecionada = {};
          this.reRenderizarTable();
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    resetarPagination() {
      this.pagination.page = 1;
      this.resetarExpanded();
    },
    verificarQuantidadeRegristrosExportacao(item) {
      const params = {
        idAcao: Number(item.idAcao),
        idApuracao: Number(item.idApuracao),
        tipoNota: 'SELLOUT_VALOR',
      };

      verificarDadosExportacao(params, this.$resource).then((response) => {
        if (response.body) {
          this.exportarNotas(item, true);
        } else {
          this.$toast(this.$t('message.sem_dados_exportacao_periodo'));
        }
      }).catch((err) => {
        this.$error(this, err);
      });
    },
    salvarValorAlterado(item, valorAtual) {
      this.apuracaoSelecionada = item;

      const params = {
        justificativaAlteracaoValorPagamento: item.justificativaAlterarRecebimento,
        vlrConfApuracao: parseFloat(item.recebimentoDefinido),
        valorRealizado: item.vlrRealizado,
        valorRealizadoOriginal: valorAtual,
      };

      this.atualizarValor(params);
    },

    atualizarValor(params = {}) {
      const parametros = params;
      parametros.indApuracaoAgrupada = this.apuracaoSelecionada.indAgrupamentoCategoriaKpi;
      parametros.id = this.apuracaoSelecionada.idApuracao;
      parametros.idContrato = this.apuracaoSelecionada.idContrato;
      parametros.dtaInicio = moment(this.apuracaoSelecionada.dataInicio, 'DD/MM/YYYY').format('YYYY-MM-DD');
      parametros.dtaFim = moment(this.apuracaoSelecionada.dataFim, 'DD/MM/YYYY').format('YYYY-MM-DD');
      this.apuracaoAcaoResource.atualizar(parametros)
        .then(() => {
          this.$toast(this.$t('message.atualizar_tabela'));
          this.resetarExpanded();
          this.reRenderizarTable();
        })
        .catch((err) => {
          this.$toast(err);
        });
    },
    reRenderizarTable() {
      this.panelKey += 1;
    },
    resetarExpanded() {
      this.expanded = [];
      this.idsApuracaoDetalhes = [];
    },
    exportarNotas(item, exportacaoRealizado) {
      if (!item.possuiNota && !exportacaoRealizado) {
        this.$toast(this.$t('message.sem_dados_notas_fiscais_apuracao'));
        return;
      }
      const params = {
        id_acao: Number(item.idAcao),
        id_apuracao: Number(item.idApuracao),
      };

      if (this.indCalculoSellout(item) && exportacaoRealizado) {
        exportacao.exportar(null, 'notas_sellout_memoria_apuracao_acao_contrato', this, params);
      } else {
        exportacao.exportar(null, 'notas_sellin_memoria_apuracao_acao', this, params);
      }
    },
  },
};
